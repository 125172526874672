import React from "react"

function Fork({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 102.39 122.88"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.529 84.792c6.151 3.507 10.299 10.125 10.299 17.713 0 11.252-9.123 20.375-20.375 20.375S.078 113.757.078 102.505c0-7.438 3.986-13.944 9.938-17.502-.581-12.169.074-37.095.193-46.967C4.107 34.516 0 27.925 0 20.375 0 9.122 9.123 0 20.375 0c11.253 0 20.376 9.122 20.376 20.375 0 7.558-4.116 14.155-10.228 17.672v23.932c7.833-2.279 15.63-2.077 22.951-1.887 3.04.079 5.972.156 8.801-.066 5.989-.471 10.397-4.068 10.645-21.415-6.687-3.342-11.28-10.252-11.28-18.236C61.64 9.122 70.762 0 82.016 0c11.252 0 20.374 9.122 20.374 20.375 0 7.131-3.664 13.406-9.212 17.046-.143 34.434-12.448 41.495-29.317 42.821-3.343.263-7.034.167-10.862.067-7.579-.198-15.893-.415-22.476 3.804 0 .223.003.45.006.679zm51.487-72.104a7.686 7.686 0 0 1 0 15.373 7.688 7.688 0 1 1 0-15.373zm-61.641 0a7.687 7.687 0 1 1 0 15.373 7.687 7.687 0 0 1 0-15.373zm.077 82.129a7.688 7.688 0 1 1-.001 15.375 7.688 7.688 0 0 1 .001-15.375z"
      />
    </svg>
  )
}

export default Fork
